const translation = {
  "节点标签": "node tag",
  "请选择key": "please select key",
  "请填写key": "please fill in key",
  "请填写value": "please fill in value",
  "请选择": "select ",
  "收起": "collapse",
  "展开": "expand",
  "查询": "query",
  "添加": "add ",
  "批量操作": "batch action",
  "操作": "action",
  "更多": "more",
  "详情": "detail",
  "修改": "edit",
  "删除": "delete",
  "批量": "batch ",
  "共": "total ",
  "条": " rows",
  "条/页": " rows/page",
  "批量导入数据": "import data",
  "批量导出": "export data",
  "注意：csv逗号分隔": "Note: csv comma-separated",
  "第一行为列的英文名": "the first line is the column’s English name",
  "下载导入模板": "download template",
  "暂无数据": "no data",
  "新查询": "new query",
  "运行": "run",
  "复制": "copy",
  "结果": "result",
  "子任务": "subtask",
  "重试": "retry",
  "准备开始": "begin",
  "解析": "analysis",
  "执行": "run",
  "输出结果": "output",
  "开始时间": "begin time",
  "运行时长": "runtime duration",
  "状态": "status",
  "下载": "download",
  "任务详情": "task detail",
  "子任务内容": "sub task sql",
  "任务信息": "task info",
  "关闭": "close",
  "下载结果": "download",
  "选择分隔符": "select delimiter",
  "正常模式": "normal",
  "智能模式": "AI",
  "AI智能生成": "AI Generation",
  "帮助链接": "help",
  "展开/关闭菜单": "collapse/expand",
  "保存": "save",
  "已保存": "saved",
  "未保存": "unsaved",
  "取消": "cancel",
  "确定": "confirm",
  "别名": "label",
  "节点别名": "node label",
  "描述": "describe",
  "创建人": "creator",
  "上次修改时间": "Last modified time",
  "调度实例": "workflow",
  "日志": "log",
  "容器": "pod",
  "定时记录": "cronjob",
  "删除节点": "delete",
  "监控": "monitor",
  "任务模板": "template",
  "模板描述": "describe",
  "名称": "name",
  "标签": "label",
  "内存申请": "memory req",
  "内存的资源使用限制，示例1G，10G， 最大100G，如需更多联系管理员": "Memory resource usage limit, example 1G, 10G, maximum 100G, contact administrator for more.",
  "CPU申请": "CPU req",
  "CPU的资源使用限制(单位核)，示例 0.4，10，最大50核，如需更多联系管理员": "CPU resource usage limit (in units of cores), example 0.4, 10, maximum 50 cores, contact administrator for more.",
  "GPU申请": "GPU req",
  "gpu的资源使用限制(单位卡)，示例:1，2，训练任务每个容器独占整卡。申请具体的卡型号，可以类似 1(V100),目前支持T4/V100/A100/VGPU": "GPU resource usage limit (in units of cards), example: 1, 2, training tasks occupy the entire card per container. To apply for specific card models, you can use something like 1(V100). Currently supports T4/V100/A100/VGPU.",
  "RDMA申请": "RDMA req",
  "RDMA的资源使用限制，示例 0，1，10，填写方式咨询管理员": "RDMA resource usage limit, example 0, 1, 10, consult administrator for filling method.",
  "超时中断": "timeout",
  "task运行时长限制，为0表示不限制(单位s)": "Task runtime limit, 0 means no limit (in seconds)",
  "重试次数": "retry",
  "task重试次数": "Task retry max count",
  "是否跳过": "skip",
  "是": "yes",
  "否": "no",
  "参数": "args ",
  "用户中心": "user info",
  "退出登录": "logout",
  "安全设置": "security",
  "用户列表": "user list",
  "角色列表": "role list",
  "用户统计": "user statistics",
  "权限列表": "permissions list",
  "视图列表": "view list",
  "权限视图关系": "permissionsOnView",
  "日志列表": "log list",
  "数据查询": "sqllab",
  "通用关系图": "pipeline",
  "数据展示": "showdata",
  "外链": "link",
  "登录": "login",
  "平台主要功能": "Main functions",
  "新建流水线": "create pipeline",
  "新手视频": "Beginner’s video",
  "新人制作一个pipeline": "Create a pipeline for new users.",
  "流水线": "pipeline",
  "我的": "mine",
  "协作": "collaboration",
  "任务流": "pipeline",
  "修改时间": "modified",
  "项目组": "project",
  "选择页数": "select page",
  "上一页": "previous",
  "下一页": "next",
  "创建会话场景": "create chat",
  "创建会话": "create chat",
  "对话模型": "chat model",
  "知识库": "knowledge",
  "确认创建": "confirm",
  "点击或者拖动文件到该区域上传数据集创建私有知识库": "click or drag the files to the area to upload the dataset to create a private knowledge base.",
  "支持 txt/markdown/pdf/csv 格式文件，请不要上传敏感数据": "supporting txt/markdown/pdf/csv formats, please do not upload sensitive data.",
  "清空当前会话场景": "clear the current conversation scene",
  "设置当前场景，如模型，知识库等": "set the current scene, such as a model or knowledge base.",
  "输入 / 调出提示（Shift + Enter = 换行）": "input / Prompt (Shift + Enter = New line)",
  "发送": "send",
  "登录超时，需要重新登录": "login timeout, need to log in again",
  "请输入": "please enter",
  "请按正确的规则输入": "please enter according to the correct rules",
  "请输入正确的长度": "please enter the correct length",
  "点击前往": "click to go",
  "请勿外传": "do not spread",
  "秒": "second",
  "分钟": "minute",
  "小时": "hour",
  "收藏": "favorite",
  "确定收藏?": "confirm favorite?",
  "确认收藏": "confirm favorite",
  "取消收藏": "cancel favorite",
  "确定取消收藏?": "confirm cancel favorite?",
  "确认取消收藏": "confirm cancel favorite",
  "操作成功": "operation successful",
  "操作失败": "operation failed",
  "用户没有修改权限": "user does not have modification permission",
  "确定删除": "confirm deletion",
  "确认删除": "confirm deletion",
  "确认": "confirm",
  "删除成功": "delete successful",
  "删除失败": "delete failed",
  "字段切换错误": "field switching error",
  "filter解析异常": "filter parsing exception",
  "请先选择": "please select first",
  "导入成功": "import successful",
  "导入失败": "import failed",
  "成功": "success",
  "失败": "failure",
  "更新": "update",
  "返回": "return",
  "确认导出数据": "confirm export data",
  "导出成功": "export successful",
  "等等": "etc.",
  "终止任务": "terminate task",
  "终止成功": "termination successful",
  "终止失败": "termination failed",
  "结果查看": "view results",
  "输入关键字（表名）搜索": "enter keyword (table name) to search",
  "已成功复制到粘贴板": "successfully copied to clipboard",
  "修改生命周期": "modify lifecycle",
  "聚合节点": "aggregation node",
  "剩余": "remaining",
  "个节点(双击展开) + ": "nodes (double-click to expand) +",
  "查询结果失败，尝试重新运行": "query result failed, try to run again",
  "标签数目达到限制": "tag limit reached",
  "点击确定完成提交": "click OK to complete the submission",
  "增加一项": "add an item",
  "上一步": "previous step",
  "下一步": "next step",
  "删除该项": "delete this item",
  "请选择时间范围": "please select a time range",
  "请选择时间": "please select a time",
  "刷新列表": "refresh list",
  "选择需要导出的列": "select the columns to export",
  "全选": "select all",
  "反选": "invert selection",
  "获取列表失败": "failed to get list",
  "获取流水线信息失败": "failed to get pipeline information",
  "格式错误": "format error",
  "流水线设置": "pipeline settings",
  "英文名(字母、数字、- 组成)，最长50个字符": "english name (letters, numbers, -), up to 50 characters",
  "每个用户使用英文逗号分隔": "each user separated by an English comma",
  "报警人": "alarm person",
  "监控状态": "monitoring status",
  "周期任务的时间设定 * * * * * 表示为 minute hour day month week": "crontab task * * * * * represents minute hour day month week",
  "调度周期": "scheduling cycle",
  "调度类型": "scheduling type",
  "新建": "new",
  "任务": "task",
  "补录起点": "make up the starting point",
  "过往依赖": "past dependencies",
  "当前pipeline可同时运行的任务流实例数目": "the number of task flow instances that the current pipeline can run simultaneously",
  "最大激活运行数": "maximum active run count",
  "任务并行数": "task parallelism",
  "pipeline中可同时运行的task数目": "number of tasks that can run simultaneously in the pipeline",
  "流向图": "flow chart",
  "全局环境变量": "global environment variables",
  "为每个task都添加的公共参数": "common parameters added to each task",
  "折叠": "collapse",
  "暂无匹配": "No matches for now",
  "搜索模板名称或描述": "search template name or description",
  "配置文档": "configuration document",
  "镜像":"images",
  "版本": "version",
  "项目设置": "setting",
  "智能推荐下游节点":"Intelligent recommendation of downstream nodes",
  "请先选择推荐节点":"Please select the recommended node first.",
  "导出数据": "Export data"
}

export default translation
