const translation = {
  "节点标签": "节点标签",
  "请选择key": "请选择key",
  "请填写key": "请填写key",
  "请填写value": "请填写value",
  "请选择": "请选择",
  "收起": "收起",
  "展开": "展开",
  "查询": "查询",
  "添加": "添加",
  "批量操作": "批量操作",
  "操作": "操作",
  "更多": "更多",
  "详情": "详情",
  "修改": "修改",
  "删除": "删除",
  "批量": "批量",
  "共": "共",
  "条": "条",
  "条/页": "条/页",
  "批量导入数据": "批量导入数据",
  "批量导出": "批量导出",
  "注意：csv逗号分隔": "注意：csv逗号分隔",
  "第一行为列的英文名": "第一行为列的英文名",
  "下载导入模板": "下载导入模板",
  "暂无数据": "暂无数据",
  "新查询": "新查询",
  "运行": "运行",
  "复制": "复制",
  "结果": "结果",
  "子任务": "子任务",
  "重试": "重试",
  "准备开始": "准备开始",
  "解析": "解析",
  "执行": "执行",
  "输出结果": "输出结果",
  "开始时间": "开始时间",
  "运行时长": "运行时长",
  "状态": "状态",
  "下载": "下载",
  "任务详情": "任务详情",
  "子任务内容": "子任务内容",
  "任务信息": "任务信息",
  "关闭": "关闭",
  "下载结果": "下载结果",
  "选择分隔符": "选择分隔符",
  "正常模式": "正常模式",
  "智能模式": "智能模式",
  "AI智能生成": "AI智能生成",
  "帮助链接": "帮助链接",
  "展开/关闭菜单": "展开/关闭菜单",
  "保存": "保存",
  "已保存": "已保存",
  "未保存": "未保存",
  "取消": "取消",
  "确定": "确定",
  "别名": "别名",
  "节点别名": "节点别名",
  "描述": "描述",
  "创建人": "创建人",
  "上次修改时间": "上次修改时间",
  "调度实例": "调度实例",
  "日志": "日志",
  "容器": "容器",
  "定时记录": "定时记录",
  "删除节点": "删除节点",
  "监控": "监控",
  "任务模板": "任务模板",
  "模板描述": "模板描述",
  "名称": "名称",
  "标签": "标签",
  "内存申请": "内存申请",
  "内存的资源使用限制，示例1G，10G， 最大100G，如需更多联系管理员": "内存的资源使用限制，示例1G，10G， 最大100G，如需更多联系管理员",
  "CPU申请": "CPU申请",
  "CPU的资源使用限制(单位核)，示例 0.4，10，最大50核，如需更多联系管理员": "CPU的资源使用限制(单位核)，示例 0.4，10，最大50核，如需更多联系管理员",
  "GPU申请": "GPU申请",
  "gpu的资源使用限制(单位卡)，示例:1，2，训练任务每个容器独占整卡。申请具体的卡型号，可以类似 1(V100),目前支持T4/V100/A100/VGPU": "gpu的资源使用限制(单位卡)，示例:1，2，训练任务每个容器独占整卡。申请具体的卡型号，可以类似 1(V100),目前支持T4/V100/A100/VGPU",
  "RDMA申请": "RDMA申请",
  "RDMA的资源使用限制，示例 0，1，10，填写方式咨询管理员": "RDMA的资源使用限制，示例 0，1，10，填写方式咨询管理员",
  "超时中断": "超时中断",
  "task运行时长限制，为0表示不限制(单位s)": "task运行时长限制，为0表示不限制(单位s)",
  "重试次数": "重试次数",
  "task重试次数": "task重试次数",
  "是否跳过": "是否跳过",
  "是": "是",
  "否": "否",
  "参数": "参数",
  "用户中心": "用户中心",
  "退出登录": "退出登录",
  "安全设置": "安全设置",
  "用户列表": "用户列表",
  "角色列表": "角色列表",
  "用户统计": "用户统计",
  "权限列表": "权限列表",
  "视图列表": "视图列表",
  "权限视图关系": "权限视图关系",
  "日志列表": "日志列表",
  "数据查询": "数据查询",
  "通用关系图": "通用关系图",
  "数据展示": "数据展示",
  "外链": "外链",
  "登录": "登录",
  "平台主要功能": "平台主要功能",
  "新建流水线": "新建流水线",
  "新手视频": "新手视频",
  "新人制作一个pipeline": "新人制作一个pipeline",
  "流水线": "流水线",
  "我的": "我的",
  "协作": "协作",
  "任务流": "任务流",
  "修改时间": "修改时间",
  "项目组": "项目组",
  "选择页数": "选择页数",
  "上一页": "上一页",
  "下一页": "下一页",
  "创建会话场景": "创建会话场景",
  "创建会话": "创建会话",
  "对话模型": "对话模型",
  "知识库": "知识库",
  "确认创建": "确认创建",
  "点击或者拖动文件到该区域上传数据集创建私有知识库": "点击或者拖动文件到该区域上传数据集创建私有知识库",
  "支持 txt/markdown/pdf/csv 格式文件，请不要上传敏感数据": "支持 txt/markdown/pdf/csv 格式文件，请不要上传敏感数据",
  "清空当前会话场景": "清空当前会话场景",
  "设置当前场景，如模型，知识库等": "设置当前场景，如模型，知识库等",
  "输入 / 调出提示（Shift + Enter = 换行）": "输入 / 调出提示（Shift + Enter = 换行）",
  "发送": "发送",
  "登录超时，需要重新登录": "登录超时，需要重新登录",
  "请输入": "请输入",
  "请按正确的规则输入": "请按正确的规则输入",
  "请输入正确的长度": "请输入正确的长度",
  "点击前往": "点击前往",
  "请勿外传": "请勿外传",
  "秒": "秒",
  "分钟": "分钟",
  "小时": "小时",
  "收藏": "收藏",
  "确定收藏?": "确定收藏?",
  "确认收藏": "确认收藏",
  "取消收藏": "取消收藏",
  "确定取消收藏?": "确定取消收藏?",
  "确认取消收藏": "确认取消收藏",
  "操作成功": "操作成功",
  "操作失败": "操作失败",
  "用户没有修改权限": "用户没有修改权限",
  "确定删除": "确定删除",
  "确认删除": "确认删除",
  "确认": "确认",
  "删除成功": "删除成功",
  "删除失败": "删除失败",
  "字段切换错误": "字段切换错误",
  "filter解析异常": "filter解析异常",
  "请先选择": "请先选择",
  "导入成功": "导入成功",
  "导入失败": "导入失败",
  "成功": "成功",
  "失败": "失败",
  "更新": "更新",
  "返回": "返回",
  "确认导出数据": "确认导出数据",
  "导出成功": "导出成功",
  "等等": "等等",
  "终止任务": "终止任务",
  "终止成功": "终止成功",
  "终止失败": "终止失败",
  "结果查看": "结果查看",
  "输入关键字（表名）搜索": "输入关键字（表名）搜索",
  "已成功复制到粘贴板": "已成功复制到粘贴板",
  "修改生命周期": "修改生命周期",
  "聚合节点": "聚合节点",
  "剩余": "剩余",
  "个节点(双击展开) + ": "个节点(双击展开) + ",
  "查询结果失败，尝试重新运行": "查询结果失败，尝试重新运行",
  "标签数目达到限制": "标签数目达到限制",
  "点击确定完成提交": "点击确定完成提交",
  "增加一项": "增加一项",
  "上一步": "上一步",
  "下一步": "下一步",
  "删除该项": "删除该项",
  "请选择时间范围": "请选择时间范围",
  "请选择时间": "请选择时间",
  "刷新列表": "刷新列表",
  "选择需要导出的列": "选择需要导出的列",
  "全选": "全选",
  "反选": "反选",
  "获取列表失败": "获取列表失败",
  "获取流水线信息失败": "获取流水线信息失败",
  "格式错误": "格式错误",
  "流水线设置": "流水线设置",
  "英文名(字母、数字、- 组成)，最长50个字符": "英文名(字母、数字、- 组成)，最长50个字符",
  "每个用户使用英文逗号分隔": "每个用户使用英文逗号分隔",
  "报警人": "报警人",
  "监控状态": "监控状态",
  "周期任务的时间设定 * * * * * 表示为 minute hour day month week": "周期任务的时间设定 * * * * * 表示为 minute hour day month week",
  "调度周期": "调度周期",
  "调度类型": "调度类型",
  "新建": "新建",
  "任务": "任务",
  "补录起点": "补录起点",
  "过往依赖": "过往依赖",
  "当前pipeline可同时运行的任务流实例数目": "当前pipeline可同时运行的任务流实例数目",
  "最大激活运行数": "最大激活运行数",
  "任务并行数": "任务并行数",
  "pipeline中可同时运行的task数目": "pipeline中可同时运行的task数目",
  "流向图": "流向图",
  "全局环境变量": "全局环境变量",
  "为每个task都添加的公共参数": "为每个task都添加的公共参数",
  "折叠": "折叠",
  "暂无匹配": "暂无匹配",
  "搜索模板名称或描述": "搜索模板名称或描述",
  "配置文档": "配置文档",
  "镜像":"镜像",
  "版本": "版本",
  "项目设置": "项目设置",
  "智能推荐下游节点":"智能推荐下游节点",
  "请先选择推荐节点":"请先选择推荐节点",
  "导出数据": "导出数据"
}

export default translation
